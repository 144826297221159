<template lang="pug">
basicPages(:title="$t('terms.title')")
  h2 {{ $t('terms.text1') }}
  p {{ $t('terms.text2') }}
  p {{ $t('terms.text3') }}
  p {{ $t('terms.text4') }}
  h2 {{ $t('terms.text5') }}
  p {{ $t('terms.text6') }}
  p {{ $t('terms.text7') }}
  p {{ $t('terms.text8') }}
  h2 {{ $t('terms.text9') }}
  p {{ $t('terms.text10') }}
  h2 {{ $t('terms.text11') }}
  p {{ $t('terms.text12') }}
  p {{ $t('terms.text13') }}
  h2 {{ $t('terms.text14') }}
  p {{ $t('terms.text15') }}
  p {{ $t('terms.text16') }}
  h2 {{ $t('terms.text17') }}
  p {{ $t('terms.text18') }}
  p {{ $t('terms.text19') }}
  p {{ $t('terms.text20') }}
  h2 {{ $t('terms.text21') }}
  p {{ $t('terms.text22') }}
  p {{ $t('terms.text23') }}
  p {{ $t('terms.text24') }}
  p {{ $t('terms.text25') }}
  p {{ $t('terms.text26') }}
  h2 {{ $t('terms.text27') }}
  p {{ $t('terms.text28') }}
  p {{ $t('terms.text29') }}
  h2 {{ $t('terms.text30') }}
  p {{ $t('terms.text31') }}
  p {{ $t('terms.text32') }}
  p {{ $t('terms.text33') }}
  h2 {{ $t('terms.text34') }}
  p {{ $t('terms.text35') }}
  p {{ $t('terms.text36') }}
  p {{ $t('terms.text37') }}
  p {{ $t('terms.text38') }}
  h2 {{ $t('terms.text39') }}
  p {{ $t('terms.text40') }}
  p {{ $t('terms.text41') }}
  h2 {{ $t('terms.text42') }}
  p {{ $t('terms.text43') }}
  p {{ $t('terms.text44') }}
  p {{ $t('terms.text45') }}
  h2 {{ $t('terms.text46') }}
  p {{ $t('terms.text47') }}
  p {{ $t('terms.text48') }}
  p {{ $t('terms.text49') }}
  p {{ $t('terms.text50') }}
  p {{ $t('terms.text51') }}
  p {{ $t('terms.text52') }}
  p {{ $t('terms.text53') }}
  h2 {{ $t('terms.text54') }}
  p {{ $t('terms.text55') }}
  p {{ $t('terms.text56') }}
  p {{ $t('terms.text57') }}
  p {{ $t('terms.text58') }}
  p {{ $t('terms.text59') }}
  p {{ $t('terms.text60') }}
  p {{ $t('terms.text61') }}
  h2 {{ $t('terms.text62') }}
  p {{ $t('terms.text63') }}
  p {{ $t('terms.text64') }}
  h2 {{ $t('terms.text65') }}
  p {{ $t('terms.text66') }}
  p {{ $t('terms.text67') }}
  p {{ $t('terms.text68') }}
  h2 {{ $t('terms.text69') }}
  p {{ $t('terms.text70') }}
  p {{ $t('terms.text71') }}
  p {{ $t('terms.text72') }}
  h2 {{ $t('terms.text73') }}
  p {{ $t('terms.text74') }}
  p {{ $t('terms.text75') }}
  p {{ $t('terms.text76') }}
</template>

<script>
import basicPages from "@/components/structure/views/basicPages";
export default {
  name: "Terminos",
  components: {
    basicPages,
  },
};
</script>

<style lang="scss">
</style>
